export const ADMIN_EMAILS = [
  "tungpham31@gmail.com",
  "sanchit@woofyclub.com",
  "annie@woofyclub.com",
  "brian@woofyclub.com",
];

export const isEmailInAdminList = (email) => {
  return ADMIN_EMAILS.includes(email);
};

export const APP_OFFICIAL_USER_UID = "oKLm4NoNADVp4fOT2gDfzCbgnr13";
